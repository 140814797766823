import { PlusIcon } from '@heroicons/react/20/solid';
import { Event } from '@components/Experience';
import { IconType } from '@components/Icon';

export const experience: Event[] = [
  {
    jobTitle: 'Software Engineer',
    location: 'Bloomberg',
    country: 'London, UK',
    blurb: '',
    points: [],
    duration: 'Jul 2025 - Present',
    icon: PlusIcon,
    iconBackground: 'bg-darkBlue',
    techStackIcons: [],
  },
  {
    jobTitle: 'Backend Engineering Intern',
    location: 'ByteDance (Global Payments)',
    country: 'Singapore',
    blurb: '',
    points: [
      'Reduced on-call tickets by 20% through building a multi-threaded, distributed API regression testing framework with Go, Kafka MQ and gRPC on ByteDance Payment Networks, enabling replay tests across multiple teams.',
      'Migrated over 78 million rows of Payment Instrument (PI) data, merging instruments across vendors (e.g., TikTok Live, TikTok Shop) to reduce transaction friction and enable seamless use of the same PI across platforms.',
      'Developed scalable and extensible APIs using Go, Thrift, gRPC, with MySQL to efficiently query the Payment Methods Console, improving performance and reliability.',
    ],
    duration: 'Aug 2024 - Dec 2024',
    icon: PlusIcon,
    iconBackground: 'bg-darkBlue',
    techStackIcons: [
      { iconType: IconType.Go },
      { iconType: IconType.Kafka },
      { iconType: IconType.Grpc },
      { iconType: IconType.MySql },
      { iconType: IconType.Thrift },
    ],
  },
  {
    jobTitle: 'Web Architecture, Software Engineering Intern',
    location: 'TikTok (Trust and Safety)',
    country: 'Singapore',
    blurb: '',
    points: [
      'Achieved a 50% reduction in feature development time by creating a low-code, multi-tenant editor with drag & drop and extensible component customization using React, JavaScript, NestJS, MongoDB, and Module Federation.',
      'Developed a library to translate ByteDance’s Universal Templating Schema into other Domain Specific Languages, ensuring interoperability across teams. Wrote extensive tests using Jest validating translation accuracy.',
      'Mitigated production errors caused by race conditions during configuration releases by developing a branching and merging feature on the low-code editor, enabling parallel workflows for multiple users.',
    ],
    duration: 'Jan 2024 - Aug 2024',
    icon: PlusIcon,
    iconBackground: 'bg-darkBlue',
    techStackIcons: [{ iconType: IconType.NextJs }, { iconType: IconType.React }, { iconType: IconType.TypeScript }],
  },
  {
    jobTitle: 'Frontend Engineering Intern',
    location: 'TikTok (Trust and Safety)',
    country: 'Singapore',
    blurb: '',
    points: [
      'Collaborated on revamp of TikTok’s moderation Data Dashboard page, leveraging React, JavaScript with SASS and Go, to create pixel-perfect reusable components resulting in a 40% improvement in overall user satisfaction.',
      'Devised and delivered an alert system for handling incorrect punishments resulting in 90% reduction of tickets.',
      'Delivered informative presentations on complex technical topics, including React internals (renderer), bundlers (Webpack, Rollup), and modern state management, showcasing in-depth knowledge and expertise in these areas.',
    ],
    duration: 'May 2023 - Aug 2023',
    icon: PlusIcon,
    iconBackground: 'bg-darkBlue',
    techStackIcons: [{ iconType: IconType.React }, { iconType: IconType.TypeScript }, { iconType: IconType.Go }],
  },
  {
    jobTitle: 'Frontend Engineering Intern',
    location: 'Datature',
    country: 'Singapore',
    blurb: 'Datature is a company that builds no-code solutions to annotate, train, visualise, and deploy computer vision models',
    points: [
      'Translated Legacy Class Based Components to Functional Components to improve code readability and support with hooks.',
      'Optimized site load time by implementing virtualization for asset lists.',
      'Built a custom node-based access control flow using React Flow.',
      'Implemented scalable CDP code using JS event delegation to track user clicks on the website.',
      "Revamped the sidebar on Datature's site by building a new collapsible design from scratch using React.",
      'Developed a new login page with Auth0 authentication to improve user security and access control.',
    ],
    duration: 'Jan 2023 - Apr 2023',
    icon: PlusIcon,
    iconBackground: 'bg-darkBlue',
    techStackIcons: [
      { iconType: IconType.NextJs },
      { iconType: IconType.React },
      { iconType: IconType.TypeScript },
      { iconType: IconType.BlueprintJs },
    ],
  },
  {
    jobTitle: 'Full Stack Engineering Intern',
    location: 'CVWO',
    country: 'Singapore',
    blurb: 'CVWO builds technology infrastructure for voluntary welfare organisations (VWO) and more.',
    points: [
      'Maintained PostgreSQL relational databases and Ruby on Rails REST APIs for Lion Befrienders, an organization dedicated to providing senior care and companionship.',
      'Led the upgrade of Ruby 2.5.3 and Rails 5.2.1 to Ruby 3.0.3 and Rails 6.1.5.',
      'Developed Progressive Web Applications using React and Ionic, and maintained a 13-year-old codebase.',
    ],
    duration: 'May 2022 - Aug 2022',
    icon: PlusIcon,
    iconBackground: 'bg-darkBlue',
    techStackIcons: [
      { iconType: IconType.Ionic },
      { iconType: IconType.React },
      { iconType: IconType.Redux },
      { iconType: IconType.TypeScript },
      { iconType: IconType.Ruby },
      { iconType: IconType.Rails },
    ],
  },
  {
    jobTitle: 'Backend Engineering Intern',
    location: 'LittleLives',
    country: 'Singapore',
    blurb: 'LittleLives is an education technology firm that provides student management software to schools.',
    points: [
      'Developed an integrated ticket-based issue tracker to enable seamless flow of information between clients, customer service, and software engineers.',
      'Utilized NodeJS to implement Webhooks and REST APIs to connect multiple services.',
      'Hosted the application on Amazon Web Services (AWS) Lambda and AWS API Gateway.',
      'Successfully deployed the code in production, utilized by 500 employees, resulting in increased productivity.',
    ],
    duration: 'Jun 2021 - Aug 2021',
    icon: PlusIcon,
    iconBackground: 'bg-darkBlue',
    techStackIcons: [
      { iconType: IconType.NodeJs },
      { iconType: IconType.JavaScript },
      { iconType: IconType.AwsApiGateway },
      { iconType: IconType.AwsLambda },
    ],
  },
  {
    jobTitle: 'Software Engineering Intern',
    location: 'Xctuality',
    country: 'Singapore',
    blurb: 'Xctuality is a Virtual Reality startup that offers unique 360° and immersive solutions.',
    points: [
      'Conceptualized and developed a unique decentralized solution to track user preferences through blockchain technology using NodeJS, Express and REST APIs.',
      'Developed software allowed for different web applications to share information anonymously.',
    ],
    duration: 'Jun 2021 - Aug 2021',
    icon: PlusIcon,
    iconBackground: 'bg-darkBlue',
    techStackIcons: [{ iconType: IconType.NodeJs }, { iconType: IconType.JavaScript }, { iconType: IconType.ExpressJs }],
  },
];
